import { default as errorZBC4lfMahoMeta } from "/home/runner/work/chat-faq/chat-faq/pages/auth/error.vue?macro=true";
import { default as request_45passwordF0sSYnEaltMeta } from "/home/runner/work/chat-faq/chat-faq/pages/auth/request-password.vue?macro=true";
import { default as reset_45passwordlyVXJMvQ8HMeta } from "/home/runner/work/chat-faq/chat-faq/pages/auth/reset-password.vue?macro=true";
import { default as sign_45inwukbARFBo8Meta } from "/home/runner/work/chat-faq/chat-faq/pages/auth/sign-in.vue?macro=true";
import { default as sign_45upNaIytQ8W2QMeta } from "/home/runner/work/chat-faq/chat-faq/pages/auth/sign-up.vue?macro=true";
import { default as edituCHfIIgUifMeta } from "/home/runner/work/chat-faq/chat-faq/pages/bots/[activeBotId]/bots/edit.vue?macro=true";
import { default as indexwsl2rvyqNSMeta } from "/home/runner/work/chat-faq/chat-faq/pages/bots/[activeBotId]/bots/index.vue?macro=true";
import { default as _91conversationId_93OegDvTDbCeMeta } from "/home/runner/work/chat-faq/chat-faq/pages/bots/[activeBotId]/conversations/[conversationId].vue?macro=true";
import { default as conversationsVSypywSKJbMeta } from "/home/runner/work/chat-faq/chat-faq/pages/bots/[activeBotId]/conversations.vue?macro=true";
import { default as indexiPth1je5eTMeta } from "/home/runner/work/chat-faq/chat-faq/pages/bots/[activeBotId]/index.vue?macro=true";
import { default as _91_91articleId_93_93Nm0ZpOo8JGMeta } from "/home/runner/work/chat-faq/chat-faq/pages/bots/[activeBotId]/wiki/[[articleId]].vue?macro=true";
import { default as _91_91clusterId_93_933ws9VfaA6QMeta } from "/home/runner/work/chat-faq/chat-faq/pages/bots/[activeBotId]/wiki/hub/[[clusterId]].vue?macro=true";
import { default as wikiNgL0wnDo3kMeta } from "/home/runner/work/chat-faq/chat-faq/pages/bots/[activeBotId]/wiki.vue?macro=true";
import { default as dummyghaHAWC9ygMeta } from "/home/runner/work/chat-faq/chat-faq/pages/dummy.vue?macro=true";
import { default as indexi1cLiGnLuvMeta } from "/home/runner/work/chat-faq/chat-faq/pages/index.vue?macro=true";
import { default as tarif_45waehlenHH0I7Og5bZMeta } from "/home/runner/work/chat-faq/chat-faq/pages/products/tarif-waehlen.vue?macro=true";
import { default as testexaM5AFh4MMeta } from "/home/runner/work/chat-faq/chat-faq/pages/test.vue?macro=true";
import { default as buttonfSullqLIE5Meta } from "/home/runner/work/chat-faq/chat-faq/pages/widget/[id]/button.vue?macro=true";
import { default as chatvR73pwkstrMeta } from "/home/runner/work/chat-faq/chat-faq/pages/widget/[id]/chat.vue?macro=true";
export default [
  {
    name: errorZBC4lfMahoMeta?.name ?? "auth-error___en",
    path: errorZBC4lfMahoMeta?.path ?? "/en/auth/error",
    meta: errorZBC4lfMahoMeta || {},
    alias: errorZBC4lfMahoMeta?.alias || [],
    redirect: errorZBC4lfMahoMeta?.redirect || undefined,
    component: () => import("/home/runner/work/chat-faq/chat-faq/pages/auth/error.vue").then(m => m.default || m)
  },
  {
    name: errorZBC4lfMahoMeta?.name ?? "auth-error___de",
    path: errorZBC4lfMahoMeta?.path ?? "/auth/error",
    meta: errorZBC4lfMahoMeta || {},
    alias: errorZBC4lfMahoMeta?.alias || [],
    redirect: errorZBC4lfMahoMeta?.redirect || undefined,
    component: () => import("/home/runner/work/chat-faq/chat-faq/pages/auth/error.vue").then(m => m.default || m)
  },
  {
    name: request_45passwordF0sSYnEaltMeta?.name ?? "auth-request-password___en",
    path: request_45passwordF0sSYnEaltMeta?.path ?? "/en/auth/request-password",
    meta: request_45passwordF0sSYnEaltMeta || {},
    alias: request_45passwordF0sSYnEaltMeta?.alias || [],
    redirect: request_45passwordF0sSYnEaltMeta?.redirect || undefined,
    component: () => import("/home/runner/work/chat-faq/chat-faq/pages/auth/request-password.vue").then(m => m.default || m)
  },
  {
    name: request_45passwordF0sSYnEaltMeta?.name ?? "auth-request-password___de",
    path: request_45passwordF0sSYnEaltMeta?.path ?? "/auth/request-password",
    meta: request_45passwordF0sSYnEaltMeta || {},
    alias: request_45passwordF0sSYnEaltMeta?.alias || [],
    redirect: request_45passwordF0sSYnEaltMeta?.redirect || undefined,
    component: () => import("/home/runner/work/chat-faq/chat-faq/pages/auth/request-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordlyVXJMvQ8HMeta?.name ?? "auth-reset-password___en",
    path: reset_45passwordlyVXJMvQ8HMeta?.path ?? "/en/auth/reset-password",
    meta: reset_45passwordlyVXJMvQ8HMeta || {},
    alias: reset_45passwordlyVXJMvQ8HMeta?.alias || [],
    redirect: reset_45passwordlyVXJMvQ8HMeta?.redirect || undefined,
    component: () => import("/home/runner/work/chat-faq/chat-faq/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordlyVXJMvQ8HMeta?.name ?? "auth-reset-password___de",
    path: reset_45passwordlyVXJMvQ8HMeta?.path ?? "/auth/reset-password",
    meta: reset_45passwordlyVXJMvQ8HMeta || {},
    alias: reset_45passwordlyVXJMvQ8HMeta?.alias || [],
    redirect: reset_45passwordlyVXJMvQ8HMeta?.redirect || undefined,
    component: () => import("/home/runner/work/chat-faq/chat-faq/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: sign_45inwukbARFBo8Meta?.name ?? "auth-sign-in___en",
    path: sign_45inwukbARFBo8Meta?.path ?? "/en/auth/sign-in",
    meta: sign_45inwukbARFBo8Meta || {},
    alias: sign_45inwukbARFBo8Meta?.alias || [],
    redirect: sign_45inwukbARFBo8Meta?.redirect || undefined,
    component: () => import("/home/runner/work/chat-faq/chat-faq/pages/auth/sign-in.vue").then(m => m.default || m)
  },
  {
    name: sign_45inwukbARFBo8Meta?.name ?? "auth-sign-in___de",
    path: sign_45inwukbARFBo8Meta?.path ?? "/auth/sign-in",
    meta: sign_45inwukbARFBo8Meta || {},
    alias: sign_45inwukbARFBo8Meta?.alias || [],
    redirect: sign_45inwukbARFBo8Meta?.redirect || undefined,
    component: () => import("/home/runner/work/chat-faq/chat-faq/pages/auth/sign-in.vue").then(m => m.default || m)
  },
  {
    name: sign_45upNaIytQ8W2QMeta?.name ?? "auth-sign-up___en",
    path: sign_45upNaIytQ8W2QMeta?.path ?? "/en/auth/sign-up",
    meta: sign_45upNaIytQ8W2QMeta || {},
    alias: sign_45upNaIytQ8W2QMeta?.alias || [],
    redirect: sign_45upNaIytQ8W2QMeta?.redirect || undefined,
    component: () => import("/home/runner/work/chat-faq/chat-faq/pages/auth/sign-up.vue").then(m => m.default || m)
  },
  {
    name: sign_45upNaIytQ8W2QMeta?.name ?? "auth-sign-up___de",
    path: sign_45upNaIytQ8W2QMeta?.path ?? "/auth/sign-up",
    meta: sign_45upNaIytQ8W2QMeta || {},
    alias: sign_45upNaIytQ8W2QMeta?.alias || [],
    redirect: sign_45upNaIytQ8W2QMeta?.redirect || undefined,
    component: () => import("/home/runner/work/chat-faq/chat-faq/pages/auth/sign-up.vue").then(m => m.default || m)
  },
  {
    name: edituCHfIIgUifMeta?.name ?? "bots-activeBotId-bots-edit___en",
    path: edituCHfIIgUifMeta?.path ?? "/en/bots/:activeBotId()/bots/edit",
    meta: edituCHfIIgUifMeta || {},
    alias: edituCHfIIgUifMeta?.alias || [],
    redirect: edituCHfIIgUifMeta?.redirect || undefined,
    component: () => import("/home/runner/work/chat-faq/chat-faq/pages/bots/[activeBotId]/bots/edit.vue").then(m => m.default || m)
  },
  {
    name: edituCHfIIgUifMeta?.name ?? "bots-activeBotId-bots-edit___de",
    path: edituCHfIIgUifMeta?.path ?? "/bots/:activeBotId()/bots/edit",
    meta: edituCHfIIgUifMeta || {},
    alias: edituCHfIIgUifMeta?.alias || [],
    redirect: edituCHfIIgUifMeta?.redirect || undefined,
    component: () => import("/home/runner/work/chat-faq/chat-faq/pages/bots/[activeBotId]/bots/edit.vue").then(m => m.default || m)
  },
  {
    name: indexwsl2rvyqNSMeta?.name ?? "bots-activeBotId-bots___en",
    path: indexwsl2rvyqNSMeta?.path ?? "/en/bots/:activeBotId()/bots",
    meta: indexwsl2rvyqNSMeta || {},
    alias: indexwsl2rvyqNSMeta?.alias || [],
    redirect: indexwsl2rvyqNSMeta?.redirect || undefined,
    component: () => import("/home/runner/work/chat-faq/chat-faq/pages/bots/[activeBotId]/bots/index.vue").then(m => m.default || m)
  },
  {
    name: indexwsl2rvyqNSMeta?.name ?? "bots-activeBotId-bots___de",
    path: indexwsl2rvyqNSMeta?.path ?? "/bots/:activeBotId()/bots",
    meta: indexwsl2rvyqNSMeta || {},
    alias: indexwsl2rvyqNSMeta?.alias || [],
    redirect: indexwsl2rvyqNSMeta?.redirect || undefined,
    component: () => import("/home/runner/work/chat-faq/chat-faq/pages/bots/[activeBotId]/bots/index.vue").then(m => m.default || m)
  },
  {
    name: conversationsVSypywSKJbMeta?.name ?? "bots-activeBotId-conversations___en",
    path: conversationsVSypywSKJbMeta?.path ?? "/en/bots/:activeBotId()/conversations",
    children: [
  {
    name: _91conversationId_93OegDvTDbCeMeta?.name ?? "bots-activeBotId-conversations-conversationId___en",
    path: _91conversationId_93OegDvTDbCeMeta?.path ?? ":conversationId()",
    meta: _91conversationId_93OegDvTDbCeMeta || {},
    alias: _91conversationId_93OegDvTDbCeMeta?.alias || [],
    redirect: _91conversationId_93OegDvTDbCeMeta?.redirect || undefined,
    component: () => import("/home/runner/work/chat-faq/chat-faq/pages/bots/[activeBotId]/conversations/[conversationId].vue").then(m => m.default || m)
  }
],
    meta: conversationsVSypywSKJbMeta || {},
    alias: conversationsVSypywSKJbMeta?.alias || [],
    redirect: conversationsVSypywSKJbMeta?.redirect || undefined,
    component: () => import("/home/runner/work/chat-faq/chat-faq/pages/bots/[activeBotId]/conversations.vue").then(m => m.default || m)
  },
  {
    name: conversationsVSypywSKJbMeta?.name ?? "bots-activeBotId-conversations___de",
    path: conversationsVSypywSKJbMeta?.path ?? "/bots/:activeBotId()/conversations",
    children: [
  {
    name: _91conversationId_93OegDvTDbCeMeta?.name ?? "bots-activeBotId-conversations-conversationId___de",
    path: _91conversationId_93OegDvTDbCeMeta?.path ?? ":conversationId()",
    meta: _91conversationId_93OegDvTDbCeMeta || {},
    alias: _91conversationId_93OegDvTDbCeMeta?.alias || [],
    redirect: _91conversationId_93OegDvTDbCeMeta?.redirect || undefined,
    component: () => import("/home/runner/work/chat-faq/chat-faq/pages/bots/[activeBotId]/conversations/[conversationId].vue").then(m => m.default || m)
  }
],
    meta: conversationsVSypywSKJbMeta || {},
    alias: conversationsVSypywSKJbMeta?.alias || [],
    redirect: conversationsVSypywSKJbMeta?.redirect || undefined,
    component: () => import("/home/runner/work/chat-faq/chat-faq/pages/bots/[activeBotId]/conversations.vue").then(m => m.default || m)
  },
  {
    name: indexiPth1je5eTMeta?.name ?? "bots-activeBotId___en",
    path: indexiPth1je5eTMeta?.path ?? "/en/bots/:activeBotId()",
    meta: indexiPth1je5eTMeta || {},
    alias: indexiPth1je5eTMeta?.alias || [],
    redirect: indexiPth1je5eTMeta?.redirect || undefined,
    component: () => import("/home/runner/work/chat-faq/chat-faq/pages/bots/[activeBotId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexiPth1je5eTMeta?.name ?? "bots-activeBotId___de",
    path: indexiPth1je5eTMeta?.path ?? "/bots/:activeBotId()",
    meta: indexiPth1je5eTMeta || {},
    alias: indexiPth1je5eTMeta?.alias || [],
    redirect: indexiPth1je5eTMeta?.redirect || undefined,
    component: () => import("/home/runner/work/chat-faq/chat-faq/pages/bots/[activeBotId]/index.vue").then(m => m.default || m)
  },
  {
    name: wikiNgL0wnDo3kMeta?.name ?? "bots-activeBotId-wiki___en",
    path: wikiNgL0wnDo3kMeta?.path ?? "/en/bots/:activeBotId()/wiki",
    children: [
  {
    name: _91_91articleId_93_93Nm0ZpOo8JGMeta?.name ?? "bots-activeBotId-wiki-articleId___en",
    path: _91_91articleId_93_93Nm0ZpOo8JGMeta?.path ?? ":articleId?",
    meta: _91_91articleId_93_93Nm0ZpOo8JGMeta || {},
    alias: _91_91articleId_93_93Nm0ZpOo8JGMeta?.alias || [],
    redirect: _91_91articleId_93_93Nm0ZpOo8JGMeta?.redirect || undefined,
    component: () => import("/home/runner/work/chat-faq/chat-faq/pages/bots/[activeBotId]/wiki/[[articleId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91clusterId_93_933ws9VfaA6QMeta?.name ?? "bots-activeBotId-wiki-hub-clusterId___en",
    path: _91_91clusterId_93_933ws9VfaA6QMeta?.path ?? "hub/:clusterId?",
    meta: _91_91clusterId_93_933ws9VfaA6QMeta || {},
    alias: _91_91clusterId_93_933ws9VfaA6QMeta?.alias || [],
    redirect: _91_91clusterId_93_933ws9VfaA6QMeta?.redirect || undefined,
    component: () => import("/home/runner/work/chat-faq/chat-faq/pages/bots/[activeBotId]/wiki/hub/[[clusterId]].vue").then(m => m.default || m)
  }
],
    meta: wikiNgL0wnDo3kMeta || {},
    alias: wikiNgL0wnDo3kMeta?.alias || [],
    redirect: wikiNgL0wnDo3kMeta?.redirect || undefined,
    component: () => import("/home/runner/work/chat-faq/chat-faq/pages/bots/[activeBotId]/wiki.vue").then(m => m.default || m)
  },
  {
    name: wikiNgL0wnDo3kMeta?.name ?? "bots-activeBotId-wiki___de",
    path: wikiNgL0wnDo3kMeta?.path ?? "/bots/:activeBotId()/wiki",
    children: [
  {
    name: _91_91articleId_93_93Nm0ZpOo8JGMeta?.name ?? "bots-activeBotId-wiki-articleId___de",
    path: _91_91articleId_93_93Nm0ZpOo8JGMeta?.path ?? ":articleId?",
    meta: _91_91articleId_93_93Nm0ZpOo8JGMeta || {},
    alias: _91_91articleId_93_93Nm0ZpOo8JGMeta?.alias || [],
    redirect: _91_91articleId_93_93Nm0ZpOo8JGMeta?.redirect || undefined,
    component: () => import("/home/runner/work/chat-faq/chat-faq/pages/bots/[activeBotId]/wiki/[[articleId]].vue").then(m => m.default || m)
  },
  {
    name: _91_91clusterId_93_933ws9VfaA6QMeta?.name ?? "bots-activeBotId-wiki-hub-clusterId___de",
    path: _91_91clusterId_93_933ws9VfaA6QMeta?.path ?? "hub/:clusterId?",
    meta: _91_91clusterId_93_933ws9VfaA6QMeta || {},
    alias: _91_91clusterId_93_933ws9VfaA6QMeta?.alias || [],
    redirect: _91_91clusterId_93_933ws9VfaA6QMeta?.redirect || undefined,
    component: () => import("/home/runner/work/chat-faq/chat-faq/pages/bots/[activeBotId]/wiki/hub/[[clusterId]].vue").then(m => m.default || m)
  }
],
    meta: wikiNgL0wnDo3kMeta || {},
    alias: wikiNgL0wnDo3kMeta?.alias || [],
    redirect: wikiNgL0wnDo3kMeta?.redirect || undefined,
    component: () => import("/home/runner/work/chat-faq/chat-faq/pages/bots/[activeBotId]/wiki.vue").then(m => m.default || m)
  },
  {
    name: dummyghaHAWC9ygMeta?.name ?? "dummy___en",
    path: dummyghaHAWC9ygMeta?.path ?? "/en/dummy",
    meta: dummyghaHAWC9ygMeta || {},
    alias: dummyghaHAWC9ygMeta?.alias || [],
    redirect: dummyghaHAWC9ygMeta?.redirect || undefined,
    component: () => import("/home/runner/work/chat-faq/chat-faq/pages/dummy.vue").then(m => m.default || m)
  },
  {
    name: dummyghaHAWC9ygMeta?.name ?? "dummy___de",
    path: dummyghaHAWC9ygMeta?.path ?? "/dummy",
    meta: dummyghaHAWC9ygMeta || {},
    alias: dummyghaHAWC9ygMeta?.alias || [],
    redirect: dummyghaHAWC9ygMeta?.redirect || undefined,
    component: () => import("/home/runner/work/chat-faq/chat-faq/pages/dummy.vue").then(m => m.default || m)
  },
  {
    name: indexi1cLiGnLuvMeta?.name ?? "index___en",
    path: indexi1cLiGnLuvMeta?.path ?? "/en",
    meta: indexi1cLiGnLuvMeta || {},
    alias: indexi1cLiGnLuvMeta?.alias || [],
    redirect: indexi1cLiGnLuvMeta?.redirect || undefined,
    component: () => import("/home/runner/work/chat-faq/chat-faq/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexi1cLiGnLuvMeta?.name ?? "index___de",
    path: indexi1cLiGnLuvMeta?.path ?? "/",
    meta: indexi1cLiGnLuvMeta || {},
    alias: indexi1cLiGnLuvMeta?.alias || [],
    redirect: indexi1cLiGnLuvMeta?.redirect || undefined,
    component: () => import("/home/runner/work/chat-faq/chat-faq/pages/index.vue").then(m => m.default || m)
  },
  {
    name: tarif_45waehlenHH0I7Og5bZMeta?.name ?? "products-tarif-waehlen___en",
    path: tarif_45waehlenHH0I7Og5bZMeta?.path ?? "/en/products/tarif-waehlen",
    meta: tarif_45waehlenHH0I7Og5bZMeta || {},
    alias: tarif_45waehlenHH0I7Og5bZMeta?.alias || [],
    redirect: tarif_45waehlenHH0I7Og5bZMeta?.redirect || undefined,
    component: () => import("/home/runner/work/chat-faq/chat-faq/pages/products/tarif-waehlen.vue").then(m => m.default || m)
  },
  {
    name: tarif_45waehlenHH0I7Og5bZMeta?.name ?? "products-tarif-waehlen___de",
    path: tarif_45waehlenHH0I7Og5bZMeta?.path ?? "/products/tarif-waehlen",
    meta: tarif_45waehlenHH0I7Og5bZMeta || {},
    alias: tarif_45waehlenHH0I7Og5bZMeta?.alias || [],
    redirect: tarif_45waehlenHH0I7Og5bZMeta?.redirect || undefined,
    component: () => import("/home/runner/work/chat-faq/chat-faq/pages/products/tarif-waehlen.vue").then(m => m.default || m)
  },
  {
    name: testexaM5AFh4MMeta?.name ?? "test___en",
    path: testexaM5AFh4MMeta?.path ?? "/en/test",
    meta: testexaM5AFh4MMeta || {},
    alias: testexaM5AFh4MMeta?.alias || [],
    redirect: testexaM5AFh4MMeta?.redirect || undefined,
    component: () => import("/home/runner/work/chat-faq/chat-faq/pages/test.vue").then(m => m.default || m)
  },
  {
    name: testexaM5AFh4MMeta?.name ?? "test___de",
    path: testexaM5AFh4MMeta?.path ?? "/test",
    meta: testexaM5AFh4MMeta || {},
    alias: testexaM5AFh4MMeta?.alias || [],
    redirect: testexaM5AFh4MMeta?.redirect || undefined,
    component: () => import("/home/runner/work/chat-faq/chat-faq/pages/test.vue").then(m => m.default || m)
  },
  {
    name: buttonfSullqLIE5Meta?.name ?? "widget-id-button___en",
    path: buttonfSullqLIE5Meta?.path ?? "/en/widget/:id()/button",
    meta: buttonfSullqLIE5Meta || {},
    alias: buttonfSullqLIE5Meta?.alias || [],
    redirect: buttonfSullqLIE5Meta?.redirect || undefined,
    component: () => import("/home/runner/work/chat-faq/chat-faq/pages/widget/[id]/button.vue").then(m => m.default || m)
  },
  {
    name: buttonfSullqLIE5Meta?.name ?? "widget-id-button___de",
    path: buttonfSullqLIE5Meta?.path ?? "/widget/:id()/button",
    meta: buttonfSullqLIE5Meta || {},
    alias: buttonfSullqLIE5Meta?.alias || [],
    redirect: buttonfSullqLIE5Meta?.redirect || undefined,
    component: () => import("/home/runner/work/chat-faq/chat-faq/pages/widget/[id]/button.vue").then(m => m.default || m)
  },
  {
    name: chatvR73pwkstrMeta?.name ?? "widget-id-chat___en",
    path: chatvR73pwkstrMeta?.path ?? "/en/widget/:id()/chat",
    meta: chatvR73pwkstrMeta || {},
    alias: chatvR73pwkstrMeta?.alias || [],
    redirect: chatvR73pwkstrMeta?.redirect || undefined,
    component: () => import("/home/runner/work/chat-faq/chat-faq/pages/widget/[id]/chat.vue").then(m => m.default || m)
  },
  {
    name: chatvR73pwkstrMeta?.name ?? "widget-id-chat___de",
    path: chatvR73pwkstrMeta?.path ?? "/widget/:id()/chat",
    meta: chatvR73pwkstrMeta || {},
    alias: chatvR73pwkstrMeta?.alias || [],
    redirect: chatvR73pwkstrMeta?.redirect || undefined,
    component: () => import("/home/runner/work/chat-faq/chat-faq/pages/widget/[id]/chat.vue").then(m => m.default || m)
  }
]