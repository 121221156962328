<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
import BugsnagPerformance, {
  DefaultRoutingProvider,
} from "@bugsnag/browser-performance";

const resolveRoute = (route: URL): string => {
  route.pathname = route.pathname.replace(/([a-z0-9]{25}|undefined)/gm, "---");

  return route.pathname;
};

BugsnagPerformance.start({
  apiKey: "f4d1e2b3640d8c228a16d779f7733616",
  routingProvider: new DefaultRoutingProvider(resolveRoute),
});

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - aifinyoCHAT` : "aifinyoCHAT";
  },
});
</script>

<style>
html {
  font-size: 16px;
}
* {
  font-family: "Inter", sans-serif;
  @apply text-sm font-medium text-default-text;
}
</style>
